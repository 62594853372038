<template>
  <header class="wiki-header">
    <div class="app-bar">
      <div class="app-bar-content">
        <a href="/www" class="logo"></a>
        <div class="bar-r-wrapper">
          <!-- 退出青少年模式 -->
          <div v-if="isTeeny" class="quit-teeny-btn" @click="quitTeenyMode">
            退出青少年模式
          </div>
          <!-- 搜索 -->
          <router-link class="search-btn" to="/search">
            <img class="sear-icon" src="../../../assets/images/sear-icon.png" alt="" />
          </router-link>
          <!-- 消息 -->
          <router-link v-if="loginUid > 0" class="msg-btn" to="/msg/1">
            <span v-if="redPoint.total">{{ redPoint.total }}</span>
            <img class="tip-icon" src="../../../assets/images/tip-icon.png" alt="" />
          </router-link>
          <!-- 进入小程序 -->
          <button class="miniapp-btn" @click="goWxMiniapp()">体验小程序</button>
          <!-- 用户 -->
          <router-link v-if="loginUid > 0" class="user-btn" :to="`/user/index/${loginUid}.html`">
            <img :src="handleImgSize(loginUser.avatar)" alt="" />
          </router-link>
          <!-- 登录 -->
          <template v-else>
            <a class="user-btn" style="font-size: 14px;" @click="onShowLogin">注册/登录</a>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// components
// import Login from "@/layout/wiki/components/login";
// import SvgIcon from "../../../components/SvgIcon/svgIcon";
// plugins
import eventBus from "@/plugins/eventBus";
import { getCookies, setCookies } from "@/plugins/cookies";

export default {
  name: "wikiHeader",
  mixins: [],
  props: {},
  model: {},
  inject: ["goWxMiniapp"],
  components: {
    // SvgIcon,
    // Login
  },
  data() {
    return {
      isShowLogin: false,
      kw: "",
      isTeeny: false
    };
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser", "redPoint"])
  },
  watch: {},
  created() {},
  mounted() {
    this.isTeeny = getCookies("underageMode") === "teeny";
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions(["loginOut"]),
    onShowLogin() {
      eventBus.$emit("onShowLogin");
    },
    quitTeenyMode() {
      setCookies("underageMode", "none", { expires: 365 * 10 });
      window.location.reload();
    }
  }
};
</script>

<style lang="less" scoped>
.wiki-header {
  .app-bar {
    width: 100%;
    height: 44px;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    z-index: @middle-z-index;

    .tip-icon {
      width: 20px;
      height: 20px;
    }
    .sear-icon {
      width: 20px;
      height: 20px;
    }
    .app-bar-content {
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
    }

    .app-bar-content {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 44px;
      background: #fff;
    }

    .back-btn {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      font-size: 20px;
      color: #666;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      color: @text-basic;
    }

    .logo {
      height: 22px;
      width: 142px;
      background: url("../../../assets/images/logo3.png") no-repeat left center;
      background-size: 100% 100%;
    }

    .bar-r-wrapper {
      display: flex;
      justify-content: flex-end;
      .quit-teeny-btn {
        display: flex;
        align-items: center;
        text-align: center;
        width: 40px;
        height: 36px;
        font-weight: 500;
        font-size: 10px;
        color: #666666;
        line-height: 14px;
        cursor: pointer;
        margin: 0 10px;
      }

      .search-btn {
        width: 21px;
        height: 21px;
        margin: 0 8px;
      }

      .msg-btn {
        position: relative;
        margin-right: 8px;
        span {
          position: absolute;
          top: -4px;
          left: 12px;
          display: flex;
          align-items: center;
          padding: 0 4px;
          word-break: keep-all;
          background: red;
          border-radius: 10px;
          height: 16px;
          font-size: 12px;
          color: #fff;
        }
      }

      .miniapp-btn {
        width: 71px;
        height: 26px;
        background: url(../../../assets/images/miniapp-btn-bg.png) no-repeat;
        background-size: 71px 26px;
        font-weight: 500;
        font-size: 10px;
        color: #ffffff;
        line-height: 20px;
        padding-bottom: 3px;
        padding-left: 8px;
        text-align: left;
        border: none;
      }

      .login-btn {
        width: 21px;
        height: 21px;
        border: none;
        background: none;
        color: #666;
        font-size: 14px;
        margin: 0 8px;
        white-space: nowrap;
        .iconfont {
          font-size: 24px;
        }
      }

      .user-btn {
        min-width: 21px;
        height: 21px;
        border: none;
        background: none;
        color: #666;
        font-size: 14px;
        margin: 0 8px 0 4px;
        white-space: nowrap;
        line-height: 21px;
        a {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
