import Vue from "vue";
import App from "./App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";
import { createI18n } from "./i18n";
// 全局混入
import "@/mixins/vueMixin";
// 插件
import "@/plugins/element-ui";
import "@/plugins/dayjs";
import "@/plugins/filter";
import "@/plugins/lazy";
import "@/plugins/xss";
// icon
import "@/assets/css/icons.css";
import "@/assets/iconFont/iconfont.css";
import "@/assets/svgIcon/iconfont.css";
import "@/assets/theme-iconfont/iconfont.css";
// css
import "@/assets/css/base.less";
import "swiper/css/swiper.css";
import "@/assets/theme/index.less";
// 查看大图
import "@/components/ImgView";

Vue.config.productionTip = false;

export function createApp({ isWww, lang }) {
  // 创建 router 实例
  const router = createRouter(isWww);
  const store = createStore();
  const i18n = createI18n(lang);
  const app = new Vue({
    // 注入 router 到根 Vue 实例
    router,
    store,
    i18n,
    render: h => h(App)
  });

  // 返回 app \ router \ store
  return {
    app,
    router,
    store
  };
}
