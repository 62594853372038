export default {
  created() {
    // 服务端预取数据合并
    this.mergeAsyncData();
  },
  methods: {
    // 合并服务端预取数据
    // store.commit('set_ssr_component_data', [])
    mergeAsyncData() {
      if (this.$options.name && this.$options.asyncData) {
        let ssrComponentData = this.$store.getters.ssrComponentData;
        if (Array.isArray(ssrComponentData)) {
          let curComponentData =
            ssrComponentData.find(item => {
              return item.componentName === this.$options.name;
            }) || {};
          // 当前组件预取数据
          let curAsyncData = curComponentData.componentData
            ? JSON.parse(curComponentData.componentData || "")
            : {};

          for (const key in curAsyncData) {
            // console.log(key)
            this[key] = curAsyncData[key];
            // this.$set(this, key, curAsyncData[key]);
          }
          // 设置结束后置空
          ssrComponentData = null;
          curComponentData = null;
          curAsyncData = null;
        }
      }
    }
  }
};
