import service from '@/plugins/request';

// 游戏详情
export function apiGameInfo (params) {
  return service({
    url: '/v1/game/detail',
    method: 'get',
    params: params
  });
}

// wiki详情编辑
export function apiWikiEdit (params) {
  return service({
    url: '/v1/protected/game/edit',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 友情链接编辑
export function apiWikiEditFriendLink (params) {
  return service({
    url: '/v1/protected/game/config',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// wikiBanner
export function apiGetRecommend (params) {
  return service({
    url: '/v1/protected/slider/list',
    method: 'get',
    data: params
  });
}

// 编辑wikiBanner
export function apiEditRecommend (params) {
  return service({
    url: '/v1/protected/slider/edit',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 创建游戏
export function apiGameAdd (params) {
  return service({
    url: '/v1/protected/game/add',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除游戏
export function apiGameDel (params) {
  return service({
    url: '/v1/protected/game/del',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 游戏列表
export function apiGamePageList (params) {
  return service({
    url: '/v1/game/pageList',
    method: 'get',
    params: params
  });
}

// 游戏版本列表
export function apiGameTimeLineList (params) {
  return service({
    url: '/v1/protected/gameTimeLine/list',
    method: 'get',
    params: params
  });
}

// 编辑游戏版本
export function apiGameTimeLineEdit (params) {
  let url = '/v1/protected/gameTimeLine/add';
  if (params.id) {
    url = '/v1/protected/gameTimeLine/edit';
  }
  return service({
    url,
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除游戏版本
export function apiGameTimeLineDel (params) {
  return service({
    url: '/v1/protected/gameTimeLine/del',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 开通wiki
export function apiGameWikiCreate (params) {
  return service({
    url: '/v1/protected/wiki/create',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 为某个帖子表情[取消]点赞
export function apiContentEmojiLike(params) {
  return service({
    url: "/v1/contentEmoji/like",
    method: "post",
    data: params
  });
}

// 表情包分组详情
export function apiEmojiGroupQueryList(params) {
  return service({
    url: "/v1/emojiGroup/queryList",
    method: "get",
    params
  });
}