<template>
  <footer class="wiki-footer">
    <div class="row title">商业合作或问题投诉</div>
    <div class="row">
      <span>微信号：Heiskee</span>
      <el-divider direction="vertical" />
      <span>邮箱：kf@gamekee.com</span>
    </div>
    <div class="row">
      <a :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">隐私政策</a>
      <el-divider direction="vertical" />
      <a :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">用户协议</a>
    </div>

    <div class="row">天津宇之广科技有限公司</div>
    <div class="row">天津市滨海新区读者新媒体大厦第八层办公室A区875室</div>
    <div class="row"><span>统一社会信用代码：91120221MA06X9QTXB</span></div>
    <div class="row">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        津ICP备20005824号-2
      </a>
    </div>
    <div class="row">
      <a href="https://www.qinglangtianjin.com/" target="_blank">
        天津市互联网违法和不良信息举报平台
      </a>
    </div>
    <div class="row">
      <a href="https://www.12377.cn/" target="_blank">
        互联网违法和不良信息举报中心
      </a>
    </div>
    <div class="row">
      <a href="https://www.12321.cn/" target="_blank">
        网络不良与垃圾信息举报受理中心
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "wikiFooter",
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.wiki-footer {
  padding: 8px;
  background: #f5f5f5;
  font-size: 12px;
  color: #666;
  line-height: 1.5;

  a {
    color: inherit;
  }
  .title {
    color: #333;
  }
  .row {
    text-align: center;
  }
}
</style>
