<template>
  <div class="user-tag-group" v-if="isShow">
    <template v-if="user.role_id == 1">
      <div class="tag1">版主</div>
    </template>
    <template v-else-if="user.role_id == 2">
      <div class="tag2">管理员</div>
    </template>
    <template v-else-if="user.role_id == 5">
      <div class="tag2">管理员</div>
    </template>
    <template v-if="user.custom_tag && user.custom_tag.tag_name">
      <div class="tag3" :style="{background:user.custom_tag.tag_color}">{{ user.custom_tag.tag_name }}</div>
    </template>
  </div>
</template>

<script>

  export default {
    name: 'UserTagGroup',
    mixins: [],
    props: {
      user: {
        type: Object,
        default: () => {
        }
      }
    },
    model: {},
    components: {},
    data () {
      return {
        tagSize: {
          mini: {
            width: '60px',
            height: '18px',
            marginRight: '5px'
          },
          middle: {
            width: '68px',
            height: '18px',
            marginRight: '10px'
          }
        }
      };
    },
    computed: {
      isShow () {
        const {
          role_id,
          custom_tag
        } = this.user;
        return role_id > 0 || (custom_tag && custom_tag.tag_name);
      }
    },
    methods: {},
    watch: {},
    created () {
    },
    mounted () {
    },
    updated () {
    },
    beforeDestroy () {
    },
    destroyed () {
    }
  };
</script>

<style scoped lang="less">
.user-tag-group {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  > div {
    font-size: 10px;
    color: #fff;
    border-radius: 2px;
    min-height: 16px;
    line-height: 16px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    & + div {
      margin-left: 5px;
    }
  }

  .tag1 {
    background: #DA5E5E;
  }

  .tag2 {
    background: #666666;
  }

  .tag3 {
    background: #999999;
  }
}
</style>
