<template>
  <div class="user-base">
    <!-- 顶部bar -->
    <div v-if="!device.desktop && !device.inApp && !isHorizontal" class="app-bar">
      <div class="app-bar-content">
        <div class="back-btn" @click="$router.back()"><i class="el-icon-arrow-left" /></div>
        <div class="title">个人中心</div>
      </div>
    </div>
    <!-- 内容 -->
    <div :class="[classBase + 'body']" class="wiki-body user-body">
      <div :class="['wiki-content', `user-${device.orientation}`]">
        <!-- <wiki-user-pc-head v-if="device.desktop" :is_followed.sync="is_followed" :user="userInfo" /> -->
        <!-- 用户信息 -->
        <wiki-user-wap-head :is_followed.sync="is_followed" :user="userInfo" />
        <!-- 用户内容 -->
        <div :class="[classBase + 'user-main']" class="user-main">
          <!-- 导航栏 -->
          <div class="nav-box">
            <div class="nav-bar">
              <template>
                <router-link
                  v-for="(item, index) in navList"
                  :key="index"
                  :to="item.link + `/${requestUid}.html`"
                  replace
                >
                  <i v-if="device.desktop" :class="['iconfont', item.icon]"></i>
                  {{ setTitle(item) }}
                </router-link>
              </template>
              <router-link v-if="device.desktop && isSelf" replace to="/user/edit">
                <i v-if="device.desktop" class="iconfont icon-a-bianzu20"></i>
                我的资料
              </router-link>
            </div>
          </div>
          <!-- 内容 -->
          <div class="router-view">
            <!--<keep-alive>-->
            <router-view />
            <!--</keep-alive>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// api
import { apiUserInfo } from "@/api/userApi";
// components
import WikiUserWapHead from "@/layout/wiki/components/wikiUserWapHead";

export default {
  name: "wikiUser",
  mixins: [],
  props: {},
  model: {},
  components: {
    WikiUserWapHead
  },
  data() {
    return {
      // 是否是自己
      isSelf: false,
      is_followed: 0,
      // 查询的用户UID
      requestUid: 0,
      isShowLogin: false,
      userInfo: {},
      navList: [
        {
          pcTitle: "我的发帖",
          otherTitle: "Ta的发帖",
          wapTitle: "发帖",
          link: "/user/index",
          icon: "icon-wodefatie",
          isPc: true
        },
        {
          pcTitle: "我的回复",
          otherTitle: "Ta的回复",
          wapTitle: "回复",
          link: "/user/comment",
          icon: "icon-wodehuifu",
          isPc: true
        },
        {
          pcTitle: "我的收藏",
          otherTitle: "Ta的收藏",
          wapTitle: "收藏",
          link: "/user/favorite",
          icon: "icon-shoucang",
          isPc: true
        },
        {
          pcTitle: "我的粉丝",
          otherTitle: "Ta的粉丝",
          wapTitle: "粉丝",
          link: "/user/follow/2",
          icon: "icon-wodefensi",
          isPc: true
        },
        {
          pcTitle: "我的关注",
          otherTitle: "Ta的关注",
          wapTitle: "关注",
          link: "/user/follow/1",
          icon: "icon-wodeguanzhu",
          isPc: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["loginUser", "loginUid"])
  },
  watch: {
    loginUser: function() {
      if (this.isSelf) {
        this.userInfo = this.loginUser;
      }
    }
  },
  async created() {},
  async beforeMount() {},
  mounted() {
    this.setRequestUser();
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    setTitle(item) {
      let title = "";
      if (this.device.desktop) {
        if (this.isSelf) {
          title = item.pcTitle;
        } else {
          title = item.otherTitle;
        }
      } else {
        title = item.wapTitle;
      }
      return title;
    },
    // 获取用户信息
    getUser() {
      apiUserInfo({ uid: this.requestUid }).then(res => {
        if (res.code == 0) {
          this.userInfo = res.data.user;
          this.userInfo.is_followed = res.data.is_followed;
          this.is_followed = res.data.is_followed;
        }
      });
    },
    // 设置需要请求的用户ID
    setRequestUser() {
      let { params, path } = this.$route;
      this.requestUid = this.loginUid || 0;
      if (params && params.id) {
        this.requestUid = params.id;
      }
      // 没有登录 并且没有访问他人，重定向到首页
      if (this.requestUid == 0) {
        this.$router.repace("/");
        return;
      } else {
        if (!params || !params.id) {
          this.$router.replace(path + `/${this.requestUid}.html`);
        }
      }

      // 判断是否是本人,非本人拉取用户信息
      if (this.requestUid == this.loginUid) {
        this.isSelf = true;
        this.userInfo = this.loginUser;
      } else {
        this.getUser();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.user-body {
  .wiki-content {
    width: 960px;
  }
}

.user-main {
  display: flex;
  justify-content: space-between;
}

.nav-box {
  width: 170px;
}

.nav-bar {
  background: #fff;
  border-radius: 6px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    border-bottom: 1px solid @border-basic;
    color: #666;
    font-size: 15px;
    transition: 0.3s;

    &:hover {
      color: #000;
    }

    .iconfont {
      font-size: 24px;
      margin-right: 12px;
      color: #d8d8d8;
    }

    &.router-link-active {
      color: #212121;

      .iconfont {
        color: #212121;
      }
    }
  }
}

.router-view {
  width: calc(100% - 186px);
  background: #fff;
  border-radius: 6px;
  padding: 28px;
  margin-bottom: 20px;
}

.wap-body {
  padding-top: 0;

  .wiki-content {
    width: 100%;
    padding: 12px;
  }

  .router-view {
    width: 100%;
    padding: 12px;
  }
}

.wap-user-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;

  .nav-box {
    width: calc(100% - 24px);
    margin: auto;
    border-bottom: 1px solid @border-basic;
  }

  .nav-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding: 0;
    border-bottom: 1px solid #f2f2f2;

    a {
      display: inline-flex;
      align-items: center;
      color: #8b8b8b;
      border: none;

      &:hover {
        color: #8b8b8b;
      }

      &.router-link-active {
        position: relative;
        color: #000;
        font-weight: bold;
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          width: 16px;
          height: 3px;
          background: #00afe8;
          border-radius: 6px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.wap-body .user-landscape {
  padding-left: 45%;

  .top-user-box {
    width: calc((100% - 60px) * 0.45);
    height: 100%;
    border-right: 5px solid #f9f9f9;
    position: fixed;
    left: 60px;
    top: 0;
    margin-top: 0;
    padding-top: 40px;
  }
}

.app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: @middle-z-index;
  border-bottom: 1px solid @border-basic;

  .app-bar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #fff;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 20px;
    color: #666;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: @text-basic;
  }
}
</style>
