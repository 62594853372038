<template>
  <div class="top-user-box">
    <div class="user-avatar">
      <img class="avatar-img" :src="handleImgSize(avatarUrlHttp, 'list')" alt="" />
      <img v-if="user.status < 1" class="avatar-ban" src="@/assets/images/avatar-ban-1.png" alt="" />
    </div>
    <div class="username">{{ user.username }}</div>
    <div class="user-id">
      uid：{{ user.uid }}
      <span class="mL20" v-if="user.last_ip_area && user.last_ip_area != '未知'">IP属地：{{ user.last_ip_area }}</span>
    </div>
    <user-tag-group v-if="user.status >= 1" class="mT5" :user="user" size="middle" />
    <div v-if="user.status >= 1" class="description">{{ user.description }}</div>
    <div v-if="user.status < 1" class="description" style="color: #FF0000;">该玩家因违反社区公约，已被封禁处理</div>

    <button v-if="user.uid == loginUid && user.status >= 1" class="edit-btn" @click="goEdit">编辑资料</button>
    <button v-if="user.uid == loginUid && user.status >= 1" class="loginout-btn" @click="loginOut('goHome')">
      退出登录
    </button>
    <button v-else-if="user.status >= 1" class="follow-btn">关注Ta</button>
    <div class="user-data-box" v-if="user.status >= 1">
      <div>
        <span>{{ user.subscribe_num || 0 }}</span>
        <p>关注</p>
      </div>
      <div>
        <span>{{ user.follow_num || 0 }}</span>
        <p>粉丝</p>
      </div>
      <div v-if="false">
        <span>255</span>
        <p>声望</p>
      </div>
      <div v-if="false">
        <span>128</span>
        <p>贡献</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserTagGroup from "../../../components/UserTag/UserTagGroup";

export default {
  name: "wikiUserWapHead",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  model: {},
  components: { UserTagGroup },
  data() {
    return {};
  },
  computed: {
    avatarUrlHttp() {
      let avatar = this.user.avatar || "";
      return avatar ? avatar.replace(/^(http:|https:)/gim, "") : "";
    }
  },
  methods: {
    ...mapActions(["loginOut"]),
    goEdit() {
      if (this.device.inApp) {
        this.jsToApp("app_goUserInfoEdit");
      } else {
        this.$router.push("/user/wap/edit");
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.top-user-box {
  margin: 20px auto 12px auto;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  position: relative;

  .user-avatar {
    position: relative;
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    border-radius: 50%;
    margin-top: -24px;
    .avatar-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .avatar-ban {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .username {
    .ellipsis;
    font-size: 20px;
    color: #000;
    line-height: 32px;
  }

  .user-id {
    color: #212121;
    font-size: 12px;
  }

  .description {
    font-size: 12px;
    color: #8b8b8b;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .edit-btn,
  .follow-btn {
    position: absolute;
    right: 0;
    top: 20px;
    background: @basic;
    color: #fff;
    padding: 0 20px 0 30px;
    border-radius: 20px 0 0 20px;
    border: none;
    font-size: 12px;
    height: 32px;
  }

  .loginout-btn {
    position: absolute;
    right: 0;
    top: 60px;
    background: @red;
    color: #fff;
    padding: 0 20px 0 30px;
    border-radius: 20px 0 0 20px;
    border: none;
    font-size: 12px;
    height: 32px;
  }

  .user-data-box {
    border-top: 1px solid @border-basic;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 12px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 12px;
        color: #8b8b8b;
      }

      span {
        font-size: 18px;
        color: #212121;
      }
    }
  }
}
</style>
