<template>
  <div class="wiki-base-container">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- <wiki-footer v-if="!isHeroGame"></wiki-footer> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import dayjs from "dayjs";
// components
// import WikiHeader from "./components/wikiHeader";
import WikiFooter from "./components/wikiFooter";

// api
import { apiWikiEntry } from "@/api/wikiApi";
// plugins
// import eventBus from "@/plugins/eventBus";
// mixins
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

export default {
  name: "wikiBase",
  mixins: [mergeAsyncDataMixin],
  props: {},
  model: {},
  components: {
    // WikiHeader,
    WikiFooter
  },
  data() {
    return {
      isShowLogin: false,
      pageHref: ""
    };
  },
  async asyncData({ store, $apis }) {
    try {
      const isWww = store.state.isWww;
      if (isWww) return;
      const { code, data } = await $apis.apiWikiEntry();
      if (code == 0) {
        store.commit("set_entryList", data.entry_list || []);
        store.commit("set_lastUpdateList", data.new_update_list || []);
        store.commit("set_adminList", data.admin_list || []);
      }
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters(["gameInfo", "isHeroGame"]),
    setBg() {
      let { Config } = this.gameInfo;
      let { $route } = this;
      // 个人中心不展示背景
      if ($route.name == "wikiHome") {
        let style = {};
        if (Config && Config.backend_img && Config.backend_img.length) {
          style.background = `url(${Config.backend_img}) no-repeat fixed 50% 0`;
          style.backgroundSize = "cover";
        }
        return style;
      } else {
        return "";
      }
    }
  },

  watch: {},
  created() {},
  beforeMount() {
    this.getEntryList();
  },
  mounted() {
    this.pageHref = window.location.href;
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    getEntryList() {
      if (this.isWww) return;
      apiWikiEntry().then(({ data }) => {
        this.$store.commit("set_entryList", data.entry_list || []);
        this.$store.commit("set_lastUpdateList", data.new_update_list || []);
        this.$store.commit("set_adminList", data.admin_list || []);
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
