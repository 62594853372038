import wikiUser from "@/layout/wiki/wikiUser";

const userRouter = [
  {
    path: "/user",
    name: "user",
    component: wikiUser,
    meta: {
      title: "个人中心",
      keepAlive: true
    },
    children: [
      {
        path: "/user/index/:id.html",
        name: "wikiUserIndex",
        component: () => import("@/views/user/index"),
        meta: {
          title: "个人中心",
          keepAlive: true
        }
      },
      {
        path: "/user/comment/:id.html",
        name: "wikiUserComment",
        component: () => import("@/views/user/comment"),
        meta: {
          title: "个人中心",
          keepAlive: true
        }
      },
      {
        path: "/user/favorite/:id.html",
        name: "wikiUserFavorite",
        component: () => import("@/views/user/favorite"),
        meta: {
          title: "个人中心",
          keepAlive: true
        }
      },
      {
        path: "/user/follow/:type/:id.html",
        name: "wikiUserFollow",
        component: () => import("@/views/user/follow"),
        meta: {
          title: "个人中心",
          keepAlive: true
        }
      }
    ]
  },

  {
    path: "/user/wap/edit",
    name: "wikiUserWapEdit",
    component: () => import("@/views/user/edit"),
    meta: {
      title: "个人中心"
    }
  }
];
export default userRouter;
