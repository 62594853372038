import dayjs from "./dayjs";

// 默认接受十位的,然后补全十三位
export function dateFormat(timestamp) {
  if (String(timestamp).length == 10) {
    timestamp = timestamp * 1000;
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let time = date.getTime();
  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;
  hours = hours >= 10 ? hours : "0" + hours;
  minutes = minutes >= 10 ? minutes : "0" + minutes;
  seconds = seconds >= 10 ? seconds : "0" + seconds;
  time = time >= 10 ? time : "0" + time;
  const obj = {
    year: year,
    month: month,
    day: day,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    time: time,
    ymd: year + "-" + month + "-" + day,
    ymdhm: year + "-" + month + "-" + day + " " + hours + ":" + minutes,
    ymdhms: year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds,
    ms: minutes + ":" + seconds,
    hs: hours + ":" + minutes
  };
  return obj;
}

export function getStyleObj(ele) {
  let styleObj = {};
  let style = ele.getAttribute("style") ? ele.getAttribute("style").split(";") : [];
  style.forEach(item => {
    item = item.replace(/\s/gim, "");
    let arr = item.split(":");
    styleObj[arr[0]] = arr[1];
  });
  return styleObj;
}

/**
 * 函数防抖
 */
export function debounce(fn, delay = 200) {
  // 记录上一次的延时器
  let timer = null;
  return function() {
    let args = arguments;
    let that = this;
    // 清除上一次延时器
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}

/**
 * 立刻执行防抖
 * @param {function} func           防抖函数
 * @param {number} wait             防抖时间间隔
 * @return {function}               返回客户调用函数
 */
function immediateDebounce(func, wait = 50) {
  let timer;
  let isRepeat = false; // 是否重复点击
  const later = () =>
    setTimeout(() => {
      isRepeat = false; // 延时wait后 isRepeat=false，timer=null，便可以调用函数
      timer = null;
    }, wait);

  return function(...params) {
    if (!timer && !isRepeat) {
      // isRepeat=false，timer=null，便可以调用函数
      func.apply(this, params);
    } else {
      isRepeat = true;
    }
    timer && clearTimeout(timer);
    timer = later();
  };
}

/**
 =* 可配置防抖函数
 * @param  {function} func        回调函数
 * @param  {number}   wait        表示时间窗口的间隔
 * @param  {boolean}  immediate   设置为ture时，是否立即调用函数
 * @return {function}             返回客户调用函数
 */
export function newDebounce(func, wait = 50, immediate = true) {
  return immediate ? immediateDebounce(func, wait) : debounce(func, wait);
}

/**
 * 函数节流
 */
export function throttle(fn, delay = 200) {
  let timer;
  return function() {
    if (!timer) {
      fn.apply(this, arguments);
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
      }, delay);
    }
  };
}

// base64转file;
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}

// 获取时长
export function calcDuration(duration) {
  let hour = Math.floor(duration / 3600);
  let minute = Math.floor((duration % 3600) / 60);
  let second = Math.floor(duration % 60);
  return `${hour > 0 ? `${hour < 10 ? `0${hour}` : hour}` : ""}${minute < 10 ? `0${minute}` : minute}:${
    second < 10 ? `0${second}` : second
  }`;
}

// 获取是否为主站
export function checkIsWww(host) {
  return [
    "www-s.gamekee.com",
    "www.gamekee.com",
    "www.stage.gamekee.com",
    "www.pre.gamekee.com",
    "www.local.gamekee.com"
  ].includes(host);
}

/**
 * 获取跳转wiki地址
 * @param {*} item
 * @returns
 */
export function getJumpWikiUrl(alias, gameId, isHasWiki) {
  // 没有wiki的，跳游戏页
  if (!isHasWiki) {
    return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/game`;
  }
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/`;
}

/**
 * 获取跳转wiki文章详情地址
 * @param {*} alias
 * @param {*} gameId
 * @param {*} detailId
 * @returns
 */
export function getJumpWikiDetailUrl(alias, gameId, detailId, isPath = false, isNewTj) {
  if (isNewTj) {
    if (isPath) {
      return `/${alias}/tj/${detailId}.html`;
    }
    return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/tj/${detailId}.html`;
  }
  // 主站点
  if (alias === "www") {
    if (isPath) {
      return `/${detailId}.html`;
    }
    return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${detailId}.html`;
  }
  if (isPath) {
    return `/${alias}/${detailId}.html`;
  }
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/${detailId}.html`;
}

/**
 * 获取跳转wiki社区地址的
 * @param {*} alias
 * @param {*} gameId
 * @returns
 */
export function getJumpWikiListUrl(alias, gameId, isHasWiki) {
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/list`;
}

/**
 * 获取跳转wiki游戏页地址
 * @param {*} alias
 * @param {*} gameId
 * @returns
 */
export function getJumpWikiGameUrl(alias, gameId, isHasWiki) {
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/game`;
}

/**
 * 获取跳转wiki日历页
 * @param {*} alias
 * @param {*} gameId
 * @returns
 */
export function getJumpWikiDateUrl(alias, gameId) {
  return `//www.${process.env.VUE_APP_WIKI_DOMAIN}/${alias}/date`;
}

/**
 * 获取游戏站点标识
 * @param {*} host
 * @returns
 */
export function getGameAlias(host) {
  host = host.split(".");
  let alias = host[0];
  return alias;
}

/**
 * 检查是否移动端内容
 * @param {*} conetentJson
 * @returns
 */
export function checkIsImageTextContent(conetentJson = []) {
  let isImageText = true;
  for (const item of conetentJson) {
    if (item.type) {
      const typeList = ["paragraph", "image", "video", "highlight-block"];
      if (!typeList.includes(item.type)) {
        isImageText = false;
        break;
      }

      if (item.children.length > 0) {
        isImageText = checkIsImageTextContent(item.children);
        if (!isImageText) break;
      }
    }
  }
  return isImageText;
}

/**
 * 判断元素是否在可视范围内
 * @param {*} el
 * @returns
 */
export function isInViewPort(el, offset = 0) {
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const top = el.getBoundingClientRect() && el.getBoundingClientRect().top;
  return top <= screenHeight + offset;
}

/**
 * 相对时间
 * @param {number} value - 秒级时间戳
 * @returns {string}
 */
export function relativeTimeFormat(value) {
  const now = dayjs();
  const inputTime = dayjs(value * 1000);

  const diffInSeconds = now.diff(inputTime, "second");
  const diffInMinutes = now.diff(inputTime, "minute");
  const diffInHours = now.diff(inputTime, "hour");
  const diffInDays = now.diff(inputTime, "day");

  // 一分钟内
  if (diffInSeconds < 60) {
    return "刚刚";
  }

  // 一小时内
  if (diffInMinutes < 60) {
    return `${diffInMinutes}分钟前`;
  }

  // 一天内
  if (diffInHours < 24) {
    return `${diffInHours}小时前`;
  }

  // 一周内
  if (diffInDays < 7) {
    return `${diffInDays}天前`;
  }

  // 具体日期
  return inputTime.format("YYYY-M-D");
}

export function toast(text, delay = 1500) {
  const div = document.createElement("div");
  div.classList.add("__temp_toast__");
  div.innerText = text;
  document.body.append(div);
  setTimeout(() => {
    div.remove();
  }, delay);
}

/**
 * 异步加载js
 * @param {*} url
 * @returns
 */
export function loadScript(url) {
  return new Promise((resolve, reject) => {
    // 创建 script 元素
    let script = document.createElement("script");
    script.type = "text/javascript"; // 设置类型为 JavaScript

    // 检查是否支持 onload 事件
    if (script.readyState) {
      // IE 浏览器
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null; // 清除事件
          resolve(); // 如果有回调函数，则执行
        }
      };
    } else {
      // 其他标准浏览器
      script.onload = function() {
        resolve(); // 加载完成后执行回调函数
      };
    }

    script.src = url; // 设置脚本的 URL
    document.head.appendChild(script); // 将 script 插入到 head 中
  });
}
/**
 * 解析路由参数
 * @param {*} url
 * @returns
 */
export function getUrlParams(url = window.location.href) {
  const params = {};
  url.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (params[k] = v));
  return params;
}
