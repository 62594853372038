import wwwBase from "../layout/wiki/wwwBase";
const wwwRouter = [
  {
    path: "/",
    component: wwwBase,
    meta: {
      title: "gamekee",
      keepAlive: true
    },
    children: [
      {
        path: "/",
        name: "wwwWikiHome",
        component: () => import("@/views/www/index"),
        meta: {
          title: "GameKee | 游戏百科攻略",
          keywords: "gamekee,游戏百科,游戏攻略",
          description:
            "GameKee（简称K站）致力于为好游戏提供wiki百科和情报攻略，在这里每个玩家都可以为自己喜欢的游戏搭建wiki专区，希望通过我们的努力，让更多的好游戏能被发现，也欢迎各位游戏热爱者加入我们，一起为自己喜欢的游戏做点有意义的事情。",
          keepAlive: true
          // grayBody: true
        }
      },
      {
        path: "/www",
        redirect: "/"
      },
      {
        path: "/home.html",
        redirect: "/"
      },
      {
        path: "/wikis",
        name: "wikiIndex",
        component: () => import("@/views/www/wikiList"),
        meta: {
          title: `好玩不花钱的安卓手游_手游排行榜${new Date().getFullYear()}前十名_策略手游游戏|Gamekee`,
          keywords: `手游排行榜,策略手游游戏,好玩不花钱的安卓手游,好玩的仙侠手游排行榜${new Date().getFullYear()},手游养成类游戏排行榜`,
          description: `GameKee的最新wiki专栏为您带来好玩的手游排行榜${new Date().getFullYear()}前十名；这里有好玩的仙侠手游排行榜，也有手游养成类游戏排行榜，更有策略手游游戏推荐给大家，方便大家找到好玩不花钱的安卓手游和IOS手游。`,
          keepAlive: true
        }
      },
      {
        path: "/www/wiki/lib",
        redirect: "/wikis"
      },
      {
        path: "/wikiList",
        redirect: "/wikis"
      },
      // 兼容老的路由
      {
        path: "/m/game/wiki-library.html",
        redirect: "/wikis"
      },
      {
        path: "/games",
        name: "gameList",
        component: () => import("@/views/www/gameList"),
        meta: {
          title: "新游专区|Gamekee",
          keepAlive: true
        }
      },
      {
        path: "/www/game/list",
        redirect: "/games"
      },
      {
        path: "/m/game/library.html",
        redirect: "/games"
      },
      {
        path: "/news",
        name: "www_gameNews",
        component: () => import("@/views/www/gameNews"),
        meta: {
          title: "游戏情报|Gamekee",
          keepAlive: true
        }
      },
      {
        path: "/www/news/list",
        redirect: "/news"
      },
      {
        path: "/gameNews",
        redirect: "/news"
      },
      {
        path: "/m/g/c.html",
        redirect: "/news"
      },
      {
        path: "/video",
        name: "www_videoList",
        component: () => import("@/views/www/videoList"),
        meta: {
          title: "游戏视频|Gamekee",
          keepAlive: true
        }
      },
      {
        path: "/www/video/list",
        redirect: "/video"
      },
      {
        path: "/videoList",
        redirect: "/video"
      },
      {
        path: "/recommendList",
        name: "recommendList",
        component: () => import("@/views/www/recommendList"),
        meta: {
          title: "k站推荐|Gamekee",
          keepAlive: true
        }
      },
      {
        path: "/search",
        name: "wwwSearch",
        component: () => import("@/views/www/search"),
        meta: {
          title: "gamekee",
          keepAlive: true
        }
      },
      {
        path: "/www/search",
        redirect: "/search"
      },
      {
        path: "/:id.html",
        name: "wwwDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          title: "wiki"
        }
      },
      {
        path: "/contributor",
        name: "wwwContributor",
        component: () => import("@/views/wiki/detail/ContributorList"),
        meta: {
          title: "wiki"
        }
      },
      {
        path: "/m/:id.html",
        redirect: "/:id.html"
      },
      {
        path: "/m",
        redirect: "/"
      },

      {
        path: "/callback",
        name: "callback",
        component: () => import("@/views/www/loginCallback"),
        meta: {
          title: "wiki",
          keepAlive: true
        }
      },
      // 兼容noWikiRoute
      // wiki游戏
      {
        path: "/game/:gameId",
        name: "noWikiGame",
        component: () => import("@/views/wiki/game/index"),
        meta: {
          noWikiRoute: true
        }
      },
      // wiki社区
      {
        path: "/game/:gameId/list",
        name: "noWikiList",
        component: () => import("@/views/wiki/list/index"),
        meta: {
          noWikiRoute: true
        }
      },
      // 游戏日历
      {
        path: "/game/:gameId/date",
        name: "noWikiDate",
        component: () => import("@/views/wiki/date/index"),
        meta: {
          noWikiRoute: true
        }
      },
      // 游戏详情
      {
        path: "/game/:gameId/:id.html",
        name: "noWikiDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          noWikiRoute: true
        }
      }
    ]
  },
  {
    path: "/wap-editor",
    name: "wwwWapEditor",
    component: () => import("@/views/wapEditor/Index"),
    meta: {
      title: "编辑"
    }
  }
];
// console.log(wwwRouter);
export default wwwRouter;
