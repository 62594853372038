import wikiBase from "@/layout/wiki/wikiBase";
// import SimulatorBase from '../layout/wiki/simulatorBase';

const kachiAlias = [
  "zsca2",
  "ba",
  "nikke",
  "lostsword",
  "hw",
  "mc",
  "lo",
  "snow",
  "battle",
  "blackbeacon",
  "overfield",
  "mfme"
].join("|");

const wikiRouter = [
  {
    path: "/:gameAlias",
    component: wikiBase,
    meta: {
      title: "wiki",
      keepAlive: true
    },
    children: [
      {
        path: "/:gameAlias",
        name: "wapHome",
        component: () => import("@/views/wiki/wapHome"),
        meta: {
          title: "wiki",
          keepAlive: true
        },
        children: [
          // 棕色尘埃2定制
          {
            path: `/:gameAlias(${kachiAlias})/`,
            name: "wikiHome",
            component: () => import("@/views/wiki/home/zsca/index"),
            meta: {
              title: "wiki",
              wikiRoute: true,
              noWikiBase: true
            }
          },
          {
            path: "/:gameAlias",
            name: "wikiHome",
            component: () => import("@/views/wiki/home/index"),
            meta: {
              title: "wiki词条",
              wikiRoute: true,
              keepAlive: true
              // grayBody: true
            }
          },
          {
            path: "/:gameAlias/entry",
            redirect: "/:gameAlias"
          },
          {
            path: "/:gameAlias/home",
            redirect: "/:gameAlias"
          },
          {
            path: "/:gameAlias/date",
            name: "wikiDate",
            component: () => import("@/views/wiki/date/index"),
            meta: {
              title: "wiki日历",
              wikiRoute: true,
              keepAlive: true
            }
          },
          {
            path: "/:gameAlias/game",
            name: "wikiGame",
            component: () => import("@/views/wiki/game/index"),
            meta: {
              title: "游戏详情",
              wikiRoute: true,
              keepAlive: true
            }
          },
          {
            path: "/:gameAlias/list",
            name: "wikiList",
            component: () => import("@/views/wiki/list/index"),
            meta: {
              title: "社区列表",
              wikiRoute: true,
              keepAlive: true
            }
          },
          {
            path: "/:gameAlias/second/:id",
            name: "wikiSecond",
            component: () => import("@/views/wiki/second/index"),
            meta: {
              title: "wiki词条",
              wikiRoute: true,
              keepAlive: true
            }
          }
        ]
      },
      {
        path: `/:gameAlias(${kachiAlias})/kachi/:id`,
        name: "kachi",
        component: () => import("@/views/wiki/home/zsca/kachi/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          noWikiBase: true
        }
      },
      {
        path: `/:gameAlias(${kachiAlias})/huodong/:id`,
        name: "huodong",
        component: () => import("@/views/wiki/home/zsca/huodong/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          noWikiBase: true
        }
      },
      // 阵容
      {
        path: `/:gameAlias(${kachiAlias})/battle`,
        name: "battle",
        component: () => import("@/views/wiki/battle/indexContent"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          noWikiBase: true
        }
      },
      {
        path: "/:gameAlias/:id.html",
        name: "wikiDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          title: "wiki",
          wikiRoute: true
        }
      },

      {
        path: "/:gameAlias/contributor",
        name: "wikiDetailContributor",
        component: () => import("@/views/wiki/detail/ContributorList"),
        meta: {
          title: "wiki",
          wikiRoute: true
        }
      },
      {
        path: "/:gameAlias/m/:id.html",
        redirect: "/:id.html"
      },
      {
        path: "/:gameAlias/m",
        redirect: "/"
      },
      {
        path: "/:gameAlias/test",
        name: "wikiTest",
        component: () => import("@/views/wiki/home/test.vue")
      }
    ]
  },
  // 编辑器
  {
    path: "/:gameAlias/wap-editor",
    name: "wikiWapEditor",
    component: () => import("@/views/wapEditor/Index"),
    meta: {
      title: "编辑器",
      wikiRoute: true
    }
  },
  {
    path: "/qyunwiki/:gameAlias",
    name: "qyunwiki",
    component: () => import("@/views/wiki/qyunWiki.vue"),
    meta: {
      title: "wiki",
      wikiRoute: true,
      // 不做青少年检查
      noUnderageCheck: true
    }
  },
  // 棕色图鉴
  {
    path: "/:gameAlias/tj/:id.html",
    name: "wikiTJ",
    component: () => import("@/views/wiki/tj/zs/index.vue"),
    meta: {
      title: "新图鉴",
      wikiRoute: true
    }
  },
  // 卡池详情
  {
    path: "/:gameAlias/card-pool/:id",
    name: "cardPoolDetail",
    component: () => import("@/views/wiki/home/cardPoolDetail"),
    meta: {
      title: "wiki",
      wikiRoute: true
    }
  },
  // 活动详情
  {
    path: "/:gameAlias/calendar-activity/:id",
    name: "calendarActivityDetail",
    component: () => import("@/views/wiki/home/calendarActivityDetail"),
    meta: {
      title: "wiki",
      wikiRoute: true
    }
  },
  // 棕色尘埃所有卡池/服区id
  {
    path: "/:gameAlias/kachi/:id",
    // redirect: "/:gameAlias/zscahuodong"
    name: "zscakachi",
    component: () => import("@/views/wiki/home/zsca/kachi/index"),
    meta: {
      title: "棕色尘埃卡池",
      wikiRoute: true
    }
  },
  // 棕色尘埃所有活动/服区id
  {
    path: "/:gameAlias/huodong/:id",
    name: "zscahuodong",
    component: () => import("@/views/wiki/home/zsca/huodong/index"),
    meta: {
      title: "棕色尘埃活动",
      wikiRoute: true
    }
  },
  /** 抽卡模拟器 */
  {
    path: "/:gameAlias(zsca2)/quilt-card/:id",
    name: "wikiQuiltCard",
    component: () => import("@/views/wiki/home/zsca/quilt-card/index"),
    meta: {
      wikiRoute: true
    }
  }
];
export default wikiRouter;
