/**
 * 1. 移动适配 rem设置
 * 2. 客户端窗口大小获取
 * @param {*} device
 * @param {*} store
 */
export function handleScreenChange(device, store) {
  let orientation = "portrait";
  // 移动端横屏适配
  if (!device.desktop) {
    let screenW = document.documentElement.clientWidth;
    let screenH = document.documentElement.clientHeight;
    if (screenW > 375 && screenW > screenH) {
      screenW = 375;
      document.documentElement.style.fontSize = screenW / 10 + "px";
    }
  }
  // 横屏
  if ([90, -90].includes(window.orientation)) {
    orientation = "landscape";
  } else {
    orientation = "portrait";
  }
  let obj = {
    orientation,
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  };
  // 屏幕变化更新屏幕数据
  store.commit("set_screenInfo", obj);
  // console.log("set_screenInfo", obj);
}
