<template>
  <div :class="['www-container', classBase + 'www-container']">
    <wiki-header v-if="!noHeaderRoutes.includes($route.name)" />
    <main :class="['www-body', classBase + 'www-body']">
      <keep-alive>
        <router-view />
      </keep-alive>
    </main>
    <wiki-footer v-if="!isHeroGame"></wiki-footer>
  </div>
</template>

<script>
// components
import WikiHeader from "./components/wikiHeader";
import WikiFooter from "./components/wikiFooter";

export default {
  name: "wwwBase",
  mixins: [],
  props: {},
  model: {},
  components: {
    WikiHeader,
    WikiFooter
  },
  data() {
    return { noHeaderRoutes: ["wwwSearch"] };
  },
  computed: {},
  methods: {},
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.www-body {
  width: 1200px;
  margin: auto;
  padding-top: 62px;
}

.wap-www-body {
  width: 100%;
  padding-top: 0;
}
</style>
