const userRouter = [
  // 图鉴详情内容，外部嵌入页
  {
    path: "/external/book-content/:id",
    name: "book-content",
    component: () => import("@/views/external/BookContentView.vue"),
    meta: {
      title: "详情",
      noUnderageCheck: true
    }
  },
  // wiki小程序外嵌页
  {
    path: "/external/wiki/:gameAlias/",
    name: "wiki-view",
    component: () => import("@/views/external/WikiView.vue"),
    meta: {
      title: "wiki",
      noUnderageCheck: true,
      wikiRoute: true
    }
  },
  // wiki小程序外嵌页
  {
    path: "/external/wiki/:gameAlias/:id.html",
    name: "wiki-detail",
    component: () => import("@/views/external/DetailView.vue"),
    meta: {
      title: "wiki",
      noUnderageCheck: true,
      wikiRoute: true
    }
  }
];
export default userRouter;
