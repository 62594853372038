<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="visible"
      append-to-body
      center
      custom-class="login-dialog normal-container"
      @close="onClose"
    >
      <div slot="title" class="login-title">
        <img class="login-head" src="@/assets/images/login-head.png" />
      </div>
      <el-form ref="loginForm" :model="form" :rules="rules" class="login-form">
        <el-form-item prop="phone">
          <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号" size="default">
            <template v-slot:prefix>
              <img class="login-input-icon" src="@/assets/images/login-phone.png" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="form.code" maxlength="6" placeholder="请输入验证码" size="default">
            <template v-slot:prefix>
              <img class="login-input-icon" src="@/assets/images/login-varfcode.png" />
            </template>
            <el-button slot="suffix" :disabled="timeCount > 0" class="code-btn" type="text" @click="showImgCode"
              >{{ codeText }}
            </el-button>
          </el-input>
          <div v-if="showVerifyCodeError" class="el-form-item__error">{{ verifyCodeErrorTxt }}</div>
        </el-form-item>
        <div class="login-submit" @click="onLogin">登录</div>
      </el-form>
      <template v-if="!isHeroGame">
        <div class="other-login-title">其他方式登录</div>
        <div class="other-login-box">
          <a class="qq-btn" @click="onQQLogin"></a>
        </div>
        <div class="xy">
          <div>
            <el-checkbox v-model="isChecked" />
            未注册手机验证后自动登录
          </div>
          <div>
            注册即表示同意
            <a class="" :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">
              《GameKee隐私政策》
            </a>
            和
            <a :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">
              《用户协议》
            </a>
            内容条例
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="imgCodeVisible"
      :width="device.desktop ? '300px' : '75%'"
      append-to-body
      center
      custom-class="login-dialog"
      top="20vh"
    >
      <div slot="title" class="login-title">请输入图片验证码</div>
      <el-form ref="imgForm" :model="form" :rules="rules" class="login-form">
        <el-form-item>
          <el-row justify="space-between" type="flex">
            <el-col :span="14" class="img-box">
              <img :src="imgSrc" alt="" class="captcha-img" />
            </el-col>
            <el-col :span="8">
              <span class="reset-img-btn" @click="getCaptcha">换一张</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="img_code">
          <el-input v-model="form.img_code" maxlength="6" placeholder="请输入图形验证码" size="default" />
        </el-form-item>
      </el-form>

      <el-button class="W100" size="default" type="primary" @click="sendSmsCode">
        确定
      </el-button>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="protocolDialog"
      width="90%"
      append-to-body
      center
      custom-class="login-dialog protocol-dialog"
      top="20vh"
    >
      <template v-slot:title>
        <div class="title">
          同意GameKee的协议
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer">
          <div class="no" @click="protocolDialog = false">取消</div>
          <div class="yes" @click="handleAgreeProtocol">确认</div>
        </div>
      </template>
      <div>
        <span>登录即代表你已阅读</span>
        <a style="color: #35caff;" :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">《GameKee隐私政策》</a>
        <span>和</span>
        <span style="color: #35caff;" :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">《用户协议》</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { apiPublicGetCaptcha, apiPublicSendSmsCode } from "@/api/publicApi";
import { apiQQAuthUrl, apiUserAuth, apiWechatAuthUrl } from "@/api/userApi";
// plugins
import eventBus from "@/plugins/eventBus";
import { setCookies, removeCookies } from "@/plugins/cookies";

export default {
  name: "login",
  mixins: [],
  inject: ["goWxMiniapp"],
  props: {},
  model: {},
  components: {},
  data() {
    return {
      type: "normal",
      visible: false,
      verifyCodeErrorTxt: "",
      showVerifyCodeError: false,
      NODE_ENV: process.env.NODE_ENV,
      imgCodeVisible: false,
      protocolDialog: false,
      loading: false,
      isChecked: true,
      loginType: "",
      form: {
        phone: "",
        code: "",
        img_code: "",
        is_hero: this.isHeroGame ? 1 : 0
      },
      imgSrc: "",
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "手机号不正确",
            trigger: "blur"
          }
        ],
        img_code: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur"
          }
        ],
        code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
        ]
      },
      codeText: "获取验证码",
      timeCount: 0,
      captchaId: ""
    };
  },
  computed: {},
  watch: {},
  created() {},
  beforeMount() {
    if (this.isHeroGame) {
      this.isChecked = true;
    }
    eventBus.$on("onShowLogin", this.onOpen);
  },
  mounted() {
    const isShowLoginMiniappTips = sessionStorage.getItem("showLoginMiniappTips");
    if (isShowLoginMiniappTips) {
      sessionStorage.removeItem("showLoginMiniappTips");
      if (!this.loginUid || !this.loginUser.is_bind_min_app) {
        setTimeout(() => {
          // 提示可前往小程序
          this.$msgbox({
            title: "",
            confirmButtonText: this.loginUid && this.loginUser.is_bind_min_app ? "前往小程序" : "绑定小程序",
            customClass: "miniapp-tips",
            confirmButtonClass: "miniapp-confirm-btn",
            message: (
              <div class="miniapp-tips-content">
                <div class="title">登录成功</div>
                <div class="message">关注小程序，随时收到回复通知和互动反馈！</div>
              </div>
            )
          }).then(() => {
            this.goWxMiniapp();
          });
          // xxs后关闭
          const duration = this.loginUid && this.loginUser.is_bind_min_app ? 3 * 1000 : 5 * 1000;
          setTimeout(() => {
            MessageBox.close();
          }, duration);
        }, 1000);
      }
    }
  },
  methods: {
    // 展示登录弹窗
    onOpen(type) {
      this.visible = true;
      this.type = type;
      // 进行中
      setCookies("login_status", 0);
    },
    onClose() {
      this.visible = false;
      // 去除进行中
      removeCookies("login_status");
      if (this.type === "commentLogin") {
        eventBus.$emit("onCommentLoginClose");
      }
      // 退出时需要
      const login_jump_path = sessionStorage.getItem("login_jump_path");
      if (login_jump_path) {
        removeCookies("login_jump_path");
      }
      // 游客提问登录
      if (this.type === "questionLogin") {
        eventBus.$emit("onQuestionLoginClose");
      }
    },
    onQQLogin() {
      if (!this.isChecked) {
        this.loginType = "qq";
        this.protocolDialog = true;
        return false;
      }
      const loginBtnEl = document.getElementById("_qqLoginBtn_");
      loginBtnEl.querySelector("a").click();
      localStorage.setItem("_qq_login_redirecturi_", location.href);
    },
    onQQWeChatLogin(type) {
      if (this.loading) return;
      if (!this.isChecked) {
        this.$message("请先同意隐私政策");
        return false;
      }

      let params = {
        state: window.location.href
      };
      this.loading = true;
      let typeApi = {
        qq: apiQQAuthUrl,
        wechat: apiWechatAuthUrl
      };
      typeApi[type](params)
        .then(res => {
          window.location.href = res.data.url;
        })
        .finally(_ => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },

    showImgCode() {
      if (this.timeCount > 0) return;
      this.$refs.loginForm.validateField(["phone"], err => {
        if (!err) {
          this.getCaptcha();
          this.imgCodeVisible = true;
        }
      });
      // this.imgCodeVisible = true;
    },
    // 获取短信验证码
    sendSmsCode() {
      let { form } = this;
      let isErr = false;
      this.$refs.loginForm.validateField(["phone", "img_code"], async err => {
        console.log(err);
        if (err) {
          isErr = true;
        }
      });
      if (isErr) return;
      let params = {
        phone: form.phone,
        img_code: form.img_code,
        is_hero: this.isHeroGame ? 1 : 0,
        captcha_id: this.captchaId
      };
      apiPublicSendSmsCode(params).then(res => {
        if (res.code == 0) {
          this.imgCodeVisible = false;
          this.codeText = "60s" + "后重新获取";
          this.timeCount = 60;
          let timer = setInterval(() => {
            if (this.timeCount == 0) {
              this.codeText = "重新获取";
              clearInterval(timer);
              return;
            }
            this.timeCount--;
            this.codeText = this.timeCount + "后重新获取";
          }, 1000);
        }
      });
    },
    // 获取图形验证码
    getCaptcha() {
      apiPublicGetCaptcha({ is_hero: this.isHeroGame ? 1 : 0 }).then(res => {
        if (res.code == 0) {
          this.imgSrc = res.data.image_url;
          this.captchaId = res.data.captcha_id;
        }
      });
    },
    loginTips() {
      return new Promise(resolve => {
        const loginTipsStatus = localStorage.getItem("loginTipsStatus");
        if (!loginTipsStatus) {
          this.$alert("打击整治网络水军，让网络空间正气充盈", "公告", {
            showClose: false,
            confirmButtonText: "确定",
            callback: action => {
              localStorage.setItem("loginTipsStatus", true);
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    },
    onLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (!valid) return;
        if (!this.isChecked) {
          this.protocolDialog = true;
          return false;
        }

        try {
          const { code, data } = await apiUserAuth(this.form);
          if (code == 0) {
            this.$store.commit("set_userInfo", data.user);
            this.$store.commit("set_userToken", data.token);
            this.$store.commit("set_userUid", data.user.uid);

            setCookies("userToken", data.token);
            setCookies("userUid", data.user.uid);
            // 登录完成
            setCookies("login_status", 1);
            sessionStorage.setItem("showLoginMiniappTips", 1);
            // 提示网络水军公告
            // await this.loginTips();
            window.location.reload();
          }
        } catch (error) {
          this.showVerifyCodeError = true;
          this.verifyCodeErrorTxt = error.msg;
          setTimeout(() => {
            this.showVerifyCodeError = false;
            this.verifyCodeErrorTxt = "";
          }, 3000);
        }
      });
    },
    handleAgreeProtocol() {
      this.isChecked = true;
      this.protocolDialog = false;
      if (this.loginType === "qq") {
        this.onQQLogin();
      } else {
        this.onLogin();
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .normal-container {
  width: 340px;
  height: 422px;
  background-color: transparent;
  background: url("./../../../assets/images/login-bg.png") center/contain no-repeat;
  .el-dialog__header {
    padding: 0;
    height: 127px;
    transform: translateY(-28px);
    .el-dialog__headerbtn {
      bottom: -370px;
      background-color: rgba(255, 255, 255, 0.24);
      top: initial;
      left: 50%;
      transform: translateX(-50%);
      right: initial;
      border-radius: 50%;
      border: 1px solid #fff;
      width: 29px;
      height: $width;
      background-image: url("../../../assets/images/login-dialog-close.png");
      background-size: 13px 13px;
      background-position: center;
      background-repeat: no-repeat;
      .el-dialog__close {
        opacity: 0;
      }
    }
  }
  .el-dialog__body {
    transform: translateY(-28px);
    padding: 0 20px;

    .el-input {
      overflow: hidden;
      .el-input__inner {
        height: 44px;
        border: 1px solid #edfbff;
        border-radius: 21px;
      }
      .el-input__suffix-inner {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        .el-button {
          border-left: 1px solid #ddf8ff;
          padding: 4px 8px;
          color: #999999;
        }
      }
    }
    .login-input-icon {
      width: 18px;
      height: $width;
      position: absolute;
      top: 50%;
      transform: translate(4px, -50%);
    }
  }
  .el-checkbox__inner {
    border-radius: 50%;
    border-color: #d1d1d1;
    &:active {
      border-color: #d1d1d1;
    }
  }
  .login-form {
    .el-form-item__content {
      line-height: 0;
    }
    .el-form-item {
      margin-bottom: 14px;
    }
  }
}
/deep/ .el-divider__text {
  background-color: #fff;
}
.login-title {
  height: 100%;
  text-align: right;
  padding-right: 2px;
  .login-head {
    max-height: 100%;
  }
}
.login-submit {
  line-height: 44px;
  border-radius: $line-height/2;
  background: url("../../../assets/images/login-submit-bg.png") center/100% no-repeat;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 4px;
  margin-top: 20px;
}
.img-box {
  display: flex;
  height: auto;
  height: 40px;
}
.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  font-weight: 400;
}

.other-login-title {
  position: relative;
  color: #c9c9c9;
  font-weight: 500;
  width: 30%;
  margin: 18px auto 0 auto;
  font-size: 11px;
  text-align: center;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 90%;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f2f2f2;
  }
  &::before {
    left: -100%;
  }
  &::after {
    right: -100%;
  }
}
.other-login-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 0 0;
  a {
    display: flex;
    width: 34px;
    height: 34px;
    background: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;

    &.qq-btn {
      background: url("./../../../assets/images/QQ.png") no-repeat center;
      background-size: 100% 100%;
    }

    &.weixin-btn {
      background: url("./../../../assets/images/weixin.png") no-repeat center #f7f7f7;
      background-size: 70% 70%;
    }
  }
}

/deep/ .xy {
  font-size: 10px;
  color: @gray;
  cursor: pointer;
  text-align: center;
  line-height: 16px;
  padding-top: 28px;
  a {
    color: @text-basic;
  }
  .el-checkbox {
    vertical-align: 2px;
    .el-checkbox__input,
    .el-checkbox__inner {
      width: 10px;
      height: 10px;
    }
    .el-checkbox__inner::after {
      top: -2px;
      left: 3px;
    }
  }
}
</style>
<style lang="less" scoped>
/deep/ .protocol-dialog {
  border-radius: 25px;
  .el-dialog__header .el-dialog__headerbtn {
    bottom: -2rem;
    background-color: rgba(255, 255, 255, 0.1);
    top: initial;
    left: 50%;
    transform: translateX(-50%);
    right: initial;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 26px;
    height: $width;
    .el-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  .title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    height: 40px;
    .no,
    .yes {
      line-height: 40px;
      border-radius: 20px;
      font-size: 15px;
      text-align: center;
      width: 45%;
    }
    .no {
      color: #05b8fe;
      background-color: #cdf1ff;
    }
    .yes {
      color: #fff;
      background-color: #05b8fe;
    }
  }
}
</style>
