import service from "@/plugins/request";

// 用户登录
export function apiUserAuth(params) {
  return service({
    url: "/v1/user/auth",
    method: "post",
    data: params,
    errorToast: false
  });
}

// 用户管理列表
export function apiWikiPermissionList(params) {
  return service({
    url: "/v1/protected/user/role",
    method: "get",
    params: params
  });
}

// 用户管理列表
export function apiUserList(params) {
  return service({
    url: "/v1/protected/user/list",
    method: "get",
    params: params
  });
}

// 封禁列表
export function apiBlockedList(params) {
  return service({
    url: "/v1/protected/user/blockedList",
    method: "get",
    params: params
  });
}

// 解封
export function apiBlockEdit(params) {
  return service({
    url: "/v1/protected/user/blockedEdit",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 封禁用户
export function apiBlockAdd(params) {
  return service({
    url: "/v1/protected/user/blockedAdd",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 获取登录用户信息
export function apiUserLoginInfo(params) {
  return service({
    url: "/v1/protected/user/myInfo",
    method: "get",
    params: params,
    errorToast: false
  });
}

// 获取其他用户信息
export function apiUserInfo(params) {
  return service({
    url: "/v1/user/info",
    method: "get",
    params: params
  });
}

// 关注列表
export function apiUserFollowList(params) {
  return service({
    url: "/v1/user/followList",
    method: "get",
    params: params
  });
}

// 收藏列表
export function apiUserFavoriteList(params) {
  return service({
    url: "/v1/user/favoriteList",
    method: "get",
    params: params
  });
}

// 用户帖子列表
export function apiUserContentList(params) {
  return service({
    url: "/v1/user/contentList",
    method: "get",
    params: params
  });
}

// 消息列表
export function apiUserMessageList(params) {
  return service({
    url: "/v1/protected/user/messageList",
    method: "get",
    params: params
  });
}

// 添加关注
export function apiUserAddFollow(params) {
  return service({
    url: "/v1/protected/user/addFollow",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 添加收藏
export function apiUserAddFavorite(params) {
  return service({
    url: "/v1/protected/user/addFavorite",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 编辑用户信息
export function apiUserEdit(params) {
  return service({
    url: "/v1/protected/user/edit",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 用户权限信息
export function apiUserMyPermission(params) {
  return service({
    url: "/v1/protected/user/myPermission",
    method: "get",
    params: params
  });
}

// 获取QQ登录地址
export function apiQQAuthUrl(params) {
  return service({
    url: "/v1/user/qqAuthUrl",
    method: "post",
    data: params
  });
}

// QQ一键登录
export function apiQQAuthByToken(params) {
  return service({
    url: "/v1/user/qqLoginByToken",
    method: "post",
    data: params
  });
}

// 获取微信登录地址
export function apiWechatAuthUrl(params) {
  return service({
    url: "/v1/user/wechatAuthUrl",
    method: "post",
    data: params
  });
}

// QQ登录
export function apiQQLogin(params) {
  return service({
    url: "/v1/user/qqLogin",
    method: "post",
    data: params
  });
}

// 微信登录
export function apiWechatLogin(params) {
  return service({
    url: "/v1/user/wechatLogin",
    method: "post",
    data: params
  });
}

// 管理员申请
export function apiAdminApply(params) {
  return service({
    url: "/v1/protected/user/adminApply",
    method: "post",
    data: params,
    successToast: true
  });
}

// 管理员申请
export function apiAdminHandle(params) {
  return service({
    url: "/v1/protected/user/adminApplyHandle",
    method: "post",
    data: params,
    successToast: true
  });
}

// 管理员申请列表
export function apiAdminApplyList(params) {
  return service({
    url: "/v1/protected/user/adminApplyList",
    method: "get",
    params: params
  });
}

// 发送系统消息
export function apiAdminSendSysMsg(params) {
  return service({
    url: "/v1/protected/user/adminMessage",
    method: "post",
    data: params,
    successToast: true
  });
}

// 红点
export function apiRedPoint(params) {
  return service({
    url: "/v1/protected/user/readPoint",
    method: "get",
    params: params
  });
}

// 登出
export function apiUserLoginOut(params) {
  return service({
    url: "/v1/protected/user/loginOut",
    method: "post",
    data: params,
    errorToast: false
  });
}

// 自定义标签
export function apiUserCustomTag(params) {
  return service({
    url: "/v1/protected/tag/customTag",
    method: "post",
    data: params,
    errorToast: false
  });
}

// 拉取客户端信息
export function apiUserSdkAuth(params) {
  return service({
    url: "/v1/user/sdkAuth",
    method: "get",
    params: params
  });
}

// 文章更新作者
export function apiContentUpdateAuthor(params) {
  return service({
    url: "/v1/protected/content/updateAuthor",
    method: "post",
    data: params,
    successToast: true
  });
}

// 用户实名认证
export function apiUserVerify(params) {
  return service({
    url: "/v1/protected/user/verify",
    method: "post",
    data: params
  });
}
