<template>
  <div class="other-conatainer">
    <div class="wiki-header">
      <a class="gamekee-logo" href="/" target="_blank"></a>
      <el-dropdown trigger="click">
        <img class="menu" src="@/assets/images/menu-icon-1.png" alt="" />
        <el-dropdown-menu slot="dropdown" class="game-select">
          <el-dropdown-item>
            <a href="/" target="_blank">
              <img class="menu-icon" src="@/assets/images/home-icon.png" alt="" />
              GameKee
            </a>
          </el-dropdown-item>
          <el-dropdown-item command="/pal/">
            <a href="/pal/" target="_blank">
              <img class="menu-icon" src="@/assets/images/study-icon.png" alt="" />
              帕鲁wiki
            </a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="wiki-body">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.game-select {
  background-color: #494a54 !important;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;
  /deep/ .el-dropdown-menu__item {
    font-size: 13px;
    font-weight: 400;
    color: #e3e3e3;
    padding: 5px 12px !important;
    &:first-child {
      border-bottom: 1px solid #5f606b;
    }
    a {
      display: flex;
      align-items: center;
      color: #e3e3e3;
    }
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
  .menu-icon {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    vertical-align: middle;
  }
}
.wiki-header {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #404040;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 16px;
  z-index: 10;
  .gamekee-logo {
    width: 150px;
    height: 24px;
    background: url("./../../assets/images/logo1.png") no-repeat center;
    background-size: 100% auto;
  }
  .menu {
    width: 24px;
    height: 24px;
  }
}

.wiki-body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
