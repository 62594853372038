import service from "@/plugins/request";

// 获取小程序scheme
export function apiGetWechatMiniAppScheme(params) {
  return service({
    url: "/v1/wechat/query-scheme",
    method: "post",
    data: params
  });
}
