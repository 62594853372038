import service from "@/plugins/request";

// 新游戏详情
export function apiGameDtail(params) {
  return service({
    url: "/v1/gameInfo/detail",
    method: "get",
    params: params
  });
}
// 游戏列表
export function apiGameList(params) {
  return service({
    url: "/v1/gameClass/pageList",
    method: "get",
    params: params
  });
}
// 视频列表
export function apiVideoList(params) {
  return service({
    url: "/v1/gameVideo/pageList",
    method: "get",
    params: params
  });
}
// 词条列表
export function apiWikiEntry(params) {
  return service({
    url: "/v1/wiki/entry",
    method: "get",
    params: params
  });
}
// wiki首页
export function apiWikiIndex(params) {
  return service({
    url: "/v1/wiki/index",
    method: "get",
    params: params
  });
}
// wiki首页
export function apiWikiIndexV2(params) {
  return service({
    url: "/v1/wiki/indexV2",
    method: "get",
    params: params
  });
}

// 获取卡池分类列表
export function apiGetCardPoolKindsList() {
  return service({
    url: "/v1/cardPoolKind/query-list",
    method: "get"
  });
}

// 获取卡池列表
export function apiGetCardPoolList(params) {
  return service({
    url: "/v1/cardPool/query-list",
    method: "get",
    params: params
  });
}

// 获取卡池详情
export function apiGetCardPoolDetail(params) {
  return service({
    url: "/v1/cardPool/detail",
    method: "get",
    params: params
  });
}

// 当前游戏下的卡池标签
export function apiCardPoolTag() {
  return service({
    url: "/v1/cardPoolTag/query-list",
    method: "get"
  });
}

// 获取活动列表
export function apiGetActivityList(params) {
  return service({
    url: "/v1/activity/page-list",
    method: "get",
    params: params
  });
}

// 获取活动详情
export function apiGetActivityDetail(params) {
  return service({
    url: "/v1/activity/detail",
    method: "get",
    params: params
  });
}

// 抽卡列表
export function apiCardPoolQueryGachaList(params) {
  return service({
    url: "/v1/cardPool/queryGachaList",
    method: "get",
    params: params
  });
}

// 抽卡记录
export function apiCardPoolGachaCardResult(params) {
  return service({
    url: "/v1/cardPool/gachaCard/result",
    method: "get",
    params: params
  });
}

// 抽卡记录清除
export function apiCardPoolGachaCardClear(params) {
  return service({
    url: "/v1/cardPool/gachaCard/clear",
    method: "post",
    data: params
  });
}

// 抽卡开始
export function apiCardPoolGachaCard(params) {
  return service({
    url: "/v1/cardPool/gachaCard",
    method: "post",
    data: params
  });
}
